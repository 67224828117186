import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import AnimalOfTheDay from "../components/sections/animal-of-the-day"
import ShareTheCare from "../components/sections/share-the-care-section"
// import AskAVet from "../components/sections/ask-a-vet"
import NewsStories from "../components/sections/news-stories"
import PetAcademy from "../components/sections/pet-academy"
import ThanksMessage from "../components/sections/thanks-message"
import SupportYourZoo from "../components/sections/support-your-zoo"
import OregonZooFoundationSection from "../components/sections/zoo-foundation"

import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  // var embedCode = '<iframe width="960" height="540" style="border:1px solid #e6e6e6" src="https://kgw.com/embeds/video/283-849fd5a9-5ba9-4f6c-968f-01e0a4175745/iframe" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>'
  return (
    <Layout>
      <SEO title="Home" />
      <ThanksMessage />
      <SupportYourZoo />
      <ShareTheCare />
      <NewsStories />
      <PetAcademy />
      <OregonZooFoundationSection />
    </Layout>
  )
}

export default IndexPage
