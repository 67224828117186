import * as styles from "./news-stories.module.scss"
import React from "react"
import VideoPlaylist from "../../jarvis/video-playlist.js"
// import KGWEmbed from "../kgw-embed.js"

const NewsStories = () => {
  return (
    <section className={styles.newsStories}>
      <article>
        <div className={styles.title}>
          <h1>News Stories</h1>
          <p>Watch this space for coverage of the Oregon Zoo from KGW</p>
        </div>

        <VideoPlaylist
          id="news-stories"
          videos={[
            {
              file: "samudra.mp4",
              title: "Meet Samudra, the 11-year-old elephant at the Oregon Zoo",
              abstract:
                'Drew Carney catches up with Samudra, "Sam," the elephant ahead of his 12th birthday later this month.',
            },
            {
              file: "giraffe.mp4",
              title: "Meet Kiden",
              abstract:
                "A 2-year-old Masai giraffe joins Buttercup and Desi at The Oregon Zoo.",
            },
            {
              file: "news-2.mp4",
              title: "Visiting the Oregon Zoo",
              abstract:
                "How the Oregon Zoo is helping you stay safe during your visit.",
            },
          ]}
          picker={true}
          path="https://media.kgwcreative.com/kgwzoo/news-stories/"
        />
      </article>
    </section>
  )
}
export default NewsStories
