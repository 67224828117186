import * as styles from "./thanks-message.module.scss"
import React from "react"
import VideoPlaylist from "../../jarvis/video-playlist.js"
import YouTube from "../youtube-embed"
import Button from "../button"

// import KGWEmbed from "../kgw-embed.js"
const ThanksMessage = () => {
  return (
    <section className={styles.container}>
      <article>
        <div className={styles.header}>
          <h1>Give an animal a second chance.</h1>
          <p>Creating a better future for wildlife.</p>
          <Button
            href={"https://app.mobilecause.com/e/8My2oA?vid=nc8i2"}
            target={"_blank"}
          >
            Donate now
          </Button>
        </div>
        <div className={styles.video}>
          <YouTube embedId={"AMP-9RBOd2U"} />
        </div>
      </article>
    </section>
  )
}
export default ThanksMessage
