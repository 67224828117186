import * as styles from "./share-the-care-section.module.scss"
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../button.js"
import LogoImage from "../../images/sharecare.jpg"

const ShareTheCare = () => {
  const backgroundImageURL = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "textures/giraffe-pattern2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).placeholderImage.childImageSharp.fluid.src

  const animals = [
    {
      name: "otter",
      class: styles.otter,
      displayName: "Southern Sea Otter",
      url:
        "https://shop.oregonzoo.org/?utm_source=website&utm_campaign=sharethecare#/Adoption/Otter",
    },
    {
      name: "polarbear",
      class: styles.polarbear,
      displayName: "Polar Bear",
      url:
        "https://shop.oregonzoo.org/?utm_source=website&utm_campaign=sharethecare#/Adoption/PolarBear",
    },
    {
      name: "redpanda",
      class: styles.redpanda,
      displayName: "Red Panda",
      url:
        "https://shop.oregonzoo.org/?utm_source=website&utm_campaign=sharethecare#/Adoption/RedPanda",
    },
    {
      name: "elephant",
      class: styles.elephant,
      displayName: "Elephant",
      url:
        "https://shop.oregonzoo.org/?utm_source=website&utm_campaign=sharethecare#/Adoption/ElephantAdoption",
    },
    {
      name: "giraffe",
      class: styles.giraffe,
      displayName: "Giraffe",
      url:
        "https://shop.oregonzoo.org/?utm_source=website&utm_campaign=sharethecare#/Adoption/Giraffe",
    },
  ]

  return (
    <section
      className={styles.container}
      style={{
        backgroundImage: `url( ${backgroundImageURL} )`,
        backgroundSize: "cover",
      }}
    >
      <article>
        <img
          src={LogoImage}
          className={styles.logo}
          alt="Share the Care logo"
        />
        <h3>
          Show your love and support for your favorite furry friend with a
          symbolic adoption!
        </h3>
        <p className={styles.description}>
          The Oregon Zoo Foundation’s <strong>Share the Care</strong> program
          benefits the zoo’s top priorities and our role in conservation of
          species facing extinction. Whether this symbolic adoption is a gift to
          yourself or an animal lover in your life, you’ll be helping share the
          care!
        </p>
        <div className={styles.animals}>
          {animals.map((animal, i) => (
            <div className={`${animal.class} ${styles.animal}`}>
              <a href={animal.url}>
                <img src={"/animals/" + animal.name + ".jpg"} alt="" />
              </a>
              <a href={animal.url}>
                <p>{animal.displayName}</p>
              </a>
            </div>
          ))}
        </div>
      </article>
    </section>
  )
}
export default ShareTheCare
