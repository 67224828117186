import * as styles from "./zoo-foundation.module.scss"
import React from "react"
import Logo from "../../images/logos/ozf-logo.png"
import Button from "../button"

// import KGWEmbed from "../kgw-embed.js"
const SupportYourZoo = () => {
  return (
    <section className={styles.container}>
      <article>
        <div className={styles.logo}>
          <img src={Logo} alt="Oregon Zoo Foundation" />
        </div>
        <div className={styles.blurb}>
          <p>
            The Oregon Zoo Foundation, the zoo's philanthropic partner, plays an
            integral role in supporting the zoo's animal welfare, conservation
            and education programs.
          </p>
          <Button href="">
            Learn about different ways to give and support your zoo today!
          </Button>
        </div>
      </article>
    </section>
  )
}
export default SupportYourZoo
