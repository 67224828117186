import * as styles from "./support-your-zoo.module.scss"
import React from "react"
import WineAdVertical from "../../images/ad-wine-vertical.jpg"
import WineAdHorizontal from "../../images/ad-wine-horizontal.jpg"

// import KGWEmbed from "../kgw-embed.js"
const SupportYourZoo = () => {
  return (
    <section className={styles.container}>
      <article>
        <div className={styles.header}>
          <h1>Support your zoo</h1>
        </div>
        <div className={styles.ads}>
          <div className={styles.ad}>
            <a href={"https://ozfwineclub.com/"}>
              <img className={styles.vertical} src={WineAdVertical} />
              <img className={styles.horizontal} src={WineAdHorizontal} />
            </a>
          </div>
        </div>
      </article>
    </section>
  )
}
export default SupportYourZoo
