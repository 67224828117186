import * as styles from "./pet-academy.module.scss"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import ResponsiveEmbed from "react-responsive-embed"
import Button from "../button.js"

const PetAcademy = () => {
  const academyLogo = useStaticQuery(graphql`
    query {
      academyLogo: file(relativePath: { eq: "pet-academy-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).academyLogo.childImageSharp.fluid.src

  const url = "https://www.youtube.com/user/BanfieldPetHospital/videos"
  return (
    <section className={styles.petAcademy}>
      <article>
        <div className={styles.title}>
          <img src={academyLogo} alt={"Banfield Pet Academy Logo"} />
          <p>
            Ever wonder what your pet is trying to tell you? Thinking it would
            be fun to teach your dog new tricks while you’re at home together?
            Curious what a veterinarian does? Banfield doctors and veterinary
            technicians share their love of pets and the profession in this
            ongoing series of short and informal videos for parents and kids.
          </p>
        </div>
        <Button href={url}>Watch on YouTube</Button>
      </article>
      <div className={styles.slidingBackground}></div>
    </section>
  )
}
export default PetAcademy
